import React from "react";
import { motion } from "framer-motion";

import { AppWrap, MotionWrap } from "../../wrapper";
import "./Blogs.scss";
import { images } from "../../constants";
import Blog from "../../components/Blog/Blog";

const Blogs = () => {
  return (
    <>
      <h2 className="head-text">
        Personal <span>Blogs</span>
      </h2>
      <div className="app__blogs-container">
        <motion.div
          className="app__blog-list"
          transition={{ duration: 0.5, delayChildren: 0.5 }}
        >
          {/* {blogs.map((blog) => ( */}
          <Blog
            name={"CI/CD Using Github Actions, CodeDeploy, and AWS EC2"}
            author={"Arnav Mahajan"}
            date={"Sep 2023"}
            content={
              "Ever wondered how to implement CI/CD with CodeDeploy and GitHub actions? This article will give viewers the step by step instructions on how to create EC2 instance on AWS and how to use CodeDeploy and GitHub actions to your advantage to achieve CI/CD in least difficult way."
            }
            link={
              "https://medium.com/@Arnav-mah/unlocking-the-power-of-ci-cd-a-step-by-step-guide-with-github-actions-codedeploy-and-aws-ec2-9f962acf9037"
            }
          />

          <Blog
            name={"CRUD Operations"}
            author={"Arnav Mahajan"}
            date={"Jun 2022"}
            content={
              "Within the computer programming world, CRUD is an elision for the 4 operations Create, Read, Update and Delete. Create, Read, Update, and  Delete (CRUD) are the four basic functions that models should be able to do, at most. In RESTful applications, CRUD often corresponds to the HTTP methods like POST, PUT, DELETE, and GET respectively."
            }
            link={
              "https://www.geeksforgeeks.org/crud-operations-and-file-upload-using-node-js-and-mongodb/"
            }
          />

          <Blog
            name={"Diabetic Retinopathy"}
            author={"Arnav Mahajan"}
            date={"Apr 2021"}
            content={
              "Recently, Diabetic Retinopathy(DR) is on the rise as a disease.It is affecting the eye of the diabetic patients because of the drastic increase in the blood sugar level. About 1.5 million people died directly from diabetes in 2019."
            }
            link={images.diabeticRetinopathy}
          />

          <Blog
            name={"Data MIning - 1"}
            author={"Arnav Mahajan"}
            date={"Apr 2025"}
            content={
              "Do you ever wondered how big OTT companies like Netflix or Prime Videos recommend the shows according to what you are currently watching? That is data mining in action (but in background). In simpler terms data mining is the process of discovering interesting patterns and knowledge from large amounts of data."
            }
            link={
              "https://medium.com/@Arnav-mah/road-to-data-mining-part-1-b06ed9d5cf66"
            }
          />

          <Blog
            name={"JOI Validation"}
            author={"Arnav Mahajan"}
            date={"Aug 2022"}
            content={
              "Over the years the JavaScript has evolved in a very short time from callbacks to promises and then to async/await. The same goes for the packages. When a request comes in, we want to make sure that it is formatted exact way it we expect to be. The backend writer needs to be super careful while writing the code for some form of protection that helps in filtering out the data. Joi gives programmers the ability to create Javascript blueprints and ensure that the application will accept properly structured input."
            }
            link={
              "https://www.geeksforgeeks.org/crud-operations-and-file-upload-using-node-js-and-mongodb/"
            }
          />

          <Blog
            name={"JOI Validation - 2"}
            author={"Arnav Mahajan"}
            date={"Sep 2023"}
            content={
              "Throughout the article, we’ll be covering various messages that can be send along with JOI valiadtions to reduce the cumbersome and hectic tasks of writing custom messages for each edge case."
            }
            link={
              "https://medium.com/@Arnav-mah/joi-validation-with-node-js-and-postman-2-8e06227d76b6"
            }
          />

          <Blog
            name={"Email Verification in NodeJs"}
            author={"Arnav Mahajan"}
            date={"Dec 2024"}
            content={
              "Ever found yourself staring at your code and wondering, ”Am I the only one worried about fake or invalid email addresses cluttering my app?” You’re not alone! Every developer who’s built a backend server has faced the age-old question: ”Should I add an email verification process?”"
            }
            link={
              "https://medium.com/@Arnav-mah/email-verification-in-node-js-507c6e65e94f"
            }
          />

          <Blog
            name={"PDF Generation in NodeJs"}
            author={"Arnav Mahajan"}
            date={"Dec 2024"}
            content={
              "Ever found yourself daydreaming about PDFs while working on your backend application? Okay, maybe not daydreaming, but admit it — PDFs are everywhere! From invoices and reports to certificates and restaurant menus, they’re an essential part of our digital lives. But how do you generate a clean, polished PDF straight from your Node.js app? Enter Puppeteer and a pinch of jsPDF magic!"
            }
            link={
              "https://medium.com/@Arnav-mah/the-fun-world-of-pdf-generation-in-node-js-with-puppeteer-de83ec44978a"
            }
          />

          {/* ))} */}
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Blogs, "app__blogs"),
  "blogs",
  "app__primarybg"
);
